import React from "react"
import { Col, Row, Container, Button } from "react-bootstrap"
import { Link } from "gatsby"

const AboutAndQuote = () => {
  return (
    <div id="about" className="pt-3 pb-5">
      <Container>
        <Row className="mt-5 mb-5 pt-5">
          <Col lg="12 text-center">
            <h1 className="text-center">
              <strong>
                Spend less time on admin.
                <br />
                Capture more revenue.
              </strong>
            </h1>
            <Button
              variant="info"
              size="lg"
              className="mt-4 p-4"
              as={Link}
              to="/get-started/"
            >
              Get Started
            </Button>
          </Col>
        </Row>
        <Row className="mt-4 mb-5">
          <Col lg="6">
            <div className="shadow p-4 mt-3">
              <h4>
                <strong>About Signaclass</strong>
              </h4>
              <p>
                At Signaclass we develop software that saves activity businesses
                administration time, saves them money and enables them to reach
                more customers, in a better way.
              </p>
            </div>
          </Col>
          <Col lg="6">
            <div className="shadow p-4 mt-3">
              <h4>
                <strong>Support</strong>
              </h4>
              <p>
                We&apos;re available to answer queries and provide technical
                assistance from Monday to Friday. Contact support using the chat
                widget or email{" "}
                <a href="mailto:info@signaclass.com">info@signaclass.com</a>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AboutAndQuote
