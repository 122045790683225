/* eslint-disable react/function-component-definition */
import { Accordion, Row, Col } from 'react-bootstrap';
import React from 'react';

const FAQAccordion = () => (
  <Row>
    <Col>
      <h1 className="text-center mb-4 mt-2">
        <strong>Frequently Asked Questions</strong>
      </h1>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>Does Signaclass charge hidden fees?</Accordion.Header>
          <Accordion.Body>
            No. You will always only be charged our monthly subscription fee for your plan size by
            us. Stripe charges an additional fee based on your location and customer payment methods
            for processing. In the event that you grow your business and need to upgrade your plan
            we will contact you to discuss your options.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>What billing schemes does Signaclass support?</Accordion.Header>
          <Accordion.Body>
            Signaclass supports monthly subscriptions for weekly rolling classes. For example, if
            you charge participants monthly but hold classes every monday we’re right for you. We’ve
            developed features like automated covering payments to simplify your administration,
            ease revenue capture and limit customer frustration. In addition to this we support
            block bookings with super flexible dates and times and a fixed, one time payment - these
            can be used for camps, trials, short courses, special events or if you run your business
            with very variable class times.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>
            Can I issue progress reports and notify of awards through Signaclass?
          </Accordion.Header>
          <Accordion.Body>
            Yes, our system lets you grade participants at your classes and issue progress cards and
            allows you to notify them if they’ve achieved any awards.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Do you offer help setting up?</Accordion.Header>
          <Accordion.Body>
            Yes, we’ll help you onboard your schedule to Signaclass when you sign up.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>
            Can adults use Signaclass to book children onto courses?
          </Accordion.Header>
          <Accordion.Body>
            Yes. Signaclass has customer accounts (who are responsible for billing and paying) and
            customers can create multiple participants. Those participants can be themselves or
            people they are responsible for like their children. Children cannot use Signaclass
            themselves, so you must always get parental consent.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>
            My business hasn’t started serving customers yet, can you help?
          </Accordion.Header>
          <Accordion.Body>
            You’ll probably want help and more time to set up! Contact us to see how we can help you
            get started smoothly.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>
            I’ve got a business with multiple branches or I run a franchise, can you help?
          </Accordion.Header>
          <Accordion.Body>
            We can support franchises or businesses in multiple regions, with dedicated
            infrastructure under a service agreement. Speak to us to see if we’re right for you.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>
            Does Signaclass send notifications when a customer signs up?
          </Accordion.Header>
          <Accordion.Body>
            Signaclass sends email notifications at key events in your customers subscription cycle
            such as new starts, cancellations and if payments are overdue.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
          <Accordion.Header>
            What features does Signaclass have to help me market my business?
          </Accordion.Header>
          <Accordion.Body>
            Signaclass has automatic email prompts to continue sign up when customers abandon the
            signup process, it gathers explicit consent for email marketing during the signup
            process allowing you to export those contacts to your email system. We also have custom
            events and GTM integration throughout the app to help turbocharge your digital marketing
            and analytics.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="9">
          <Accordion.Header>
            Can I set my own terms and conditions and logo in the app?
          </Accordion.Header>
          <Accordion.Body>
            Yes. You can (and should) set your own terms, privacy policy, logo and brand name
            through the configuration page.
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="10">
          <Accordion.Header>
            I’m registered for Sales Tax or VAT, does Signaclass help me stay compliant?
          </Accordion.Header>
          <Accordion.Body>
            Signaclass allows you to configure your tax rate with stripe. This will then display on
            your generated documents and help meet your tax needs. It is ultimately your
            responsibility to ensure our software meets your needs so speak to your accountant if
            you are uncertain.
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </Col>
  </Row>
);

export default FAQAccordion;
