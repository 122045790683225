import React from 'react'; // import the component part of React
import { Link } from 'gatsby';
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"
import {
  Container, Row, Col, Button,
} from 'react-bootstrap';
// Components and Widgets
import Layout from '../components/layout';
import FadeIn from '../components/fade-in';
import FAQAccordion from '../components/faqs';
import AboutAndQuote from '../components/aboutandquote';

const Index = () => {
  return (
    <Layout>
    <Seo title="Signaclass - Class Management & Billing System" description="Signaclass is a class management, listing and payments system for activity businesses that want to reduce their administration time and reduce friction in their signup process."/>
    
    <div>
      <FadeIn delay={200} duration={1500}>
        <div className="shaded pt-5 pb-5" id="header">
          <Container id="header">
            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6">
                <h1>
                  <strong>The smart way to manage customer payments.</strong>
                </h1>
                <p className="flow-text mt-5">
                  Signaclass allows activity businesses and sport’s clubs to capture revenue online,
                  whilst reducing their admin and easing the signup process. 30 Day Free Trial.
                </p>
                <Link to="/get-started">
                  <Button variant="info" size="lg" className="mt-4 shake">
                    Get Started
                  </Button>
                </Link>
              </Col>
              <Col lg="6 mt-5 mb-5">
                <StaticImage src='../images/PlayGroup.svg' placeholder="blurred" />
              </Col>
            </Row>
          </Container>
        </div>

        <div id="features" className="pt-5 pb-5">
          <Container>
            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="12">
                <h2 className="text-center display-3">
                  <strong>Features.</strong>
                </h2>
              </Col>
            </Row>

            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6">
              <StaticImage src='../images/Subscription.svg' placeholder="blurred" />
              </Col>
              <Col lg="6">
                <h3>
                  <strong>Subscription Payments</strong>
                </h3>
                <p className="flow-text mt-5">
                  Signup customers to a rolling charge and let them handle cancellations themselves
                  at the end of the month. Minimise your administration time whilst resolving common
                  frustrations.
                </p>
              </Col>
            </Row>

            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6" className="order-lg-2">
              <StaticImage src='../images/Automation.svg' placeholder="blurred" />
              </Col>
              <Col lg="6" className="order-lg-1">
                <h3>
                  <strong>Monthly Charge, Weekly Schedule</strong>
                </h3>
                <p className="flow-text mt-5">
                  We know activity businesses costs are normally structured on a monthly basis, but
                  parents and adult learners normally expect classes to be weekly. Signaclass
                  supports monthly payments with a weekly schedule alongside block payments with
                  variable dates &amp; times for special classes.
                </p>
              </Col>
            </Row>

            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6">
              <StaticImage src='../images/Schedule.svg' placeholder="blurred" />
              </Col>
              <Col lg="6">
                <h3>
                  <strong>Automatic Covering Payments</strong>
                </h3>
                <p className="flow-text mt-5">
                  Sessions listed on Signaclass can dynamically calculate a customer&apos;s price on
                  signup based on the day of the week and week of the month that they’ve signed up.
                  Feedback from businesses told us that calculating covering payments was a constant
                  cause of wasted hours and customer complaints.
                </p>
              </Col>
            </Row>

            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6" className="order-lg-2">
              <StaticImage src='../images/WaitingLists.svg' placeholder="blurred" />
              </Col>
              <Col lg="6" className="order-lg-1">
                <h3>
                  <strong>Waiting Lists</strong>
                </h3>
                <p className="flow-text mt-5">
                  If a class is full, Signaclass allows your customers to register their interest
                  for that day and time. You can then invite them to subscribe when you are ready
                  and they will receive an email inviting them to subscribe.
                </p>
              </Col>
            </Row>

            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6">
              <StaticImage src='../images/TutorAccounts.svg' placeholder="blurred" />
              </Col>
              <Col lg="6">
                <h3>
                  <strong>Tutor Accounts</strong>
                </h3>
                <p className="flow-text mt-5">
                  If you manage a business with multiple trainers Signaclass allows you to set up
                  tutors and assign them classes. Tutors have a restricted view to manage their
                  registers for distributed management without interfering with each other.
                </p>
              </Col>
            </Row>

            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6" className="order-lg-2">
              <StaticImage src='../images/Analytics.svg' placeholder="blurred" />
              </Col>
              <Col lg="6" className="order-lg-1">
                <h3>
                  <strong>Google Tag Manager Integration</strong>
                </h3>
                <p className="flow-text mt-5">
                  No more opaque booking systems. Integrate your GTM tag and use our pre-coded
                  events to drive more revenue on your chosen marketing platform. We know modern
                  digital marketing needs good data, so we’ve put it in Signaclass from the start.
                </p>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col lg="12" className="text-center">
                <div className="shadow p-5">
                  {' '}
                  <h2 className="text-center">
                    <strong>See Full List Of Features.</strong>
                  </h2>
                  <Button variant="primary" className="mt-4" as={Link} to="/features">
                    View
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="faq" className="pt-5 pb-5">
          <Container>
            <FAQAccordion />
          </Container>
        </div>
        <div id="verticals" className="pt-5 pb-5">
          <Container>
            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="12">
              <h2 className="text-center display-3">
                  <strong>For Your Business.</strong>
                </h2>
              </Col>
            </Row>
            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6" className="order-lg-2">
                <h3>
                  <strong>Sports</strong>
                </h3>
                <p className="flow-text mt-5">
                  Signaclass is used by Sports Trainers and Clubs to advertise their schedules, take
                  payments and manage attendance.
                </p>
              </Col>
              <Col lg="6" className="order-lg-1">
              <StaticImage src='../images/Sports.svg' placeholder="blurred" />
              </Col>
            </Row>
            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6">
                <h3>
                  <strong>Hobbies and Skills</strong>
                </h3>
                <p className="flow-text mt-5">
                  Businesses that run classes and sessions teaching valuable skills like
                  self-defence, cooking and dance use Signaclass to maximise revenue.
                </p>
              </Col>
              <Col lg="6">
              <StaticImage src='../images/AdultClasses.svg' placeholder="blurred" />
              </Col>
            </Row>
            <Row className="mt-5 mb-5 align-items-center">
              <Col lg="6" className="order-lg-2">
                <h3>
                  <strong>Recreation &amp; Activities</strong>
                </h3>
                <p className="flow-text mt-5">
                  Businesses that operate play groups and recreation activities use Signaclass to
                  improve their online presence and minimise administration time.
                </p>
              </Col>
              <Col lg="6" className="order-lg-1">
              <StaticImage src='../images/ActivityGroup.svg' placeholder="blurred" />
              </Col>
            </Row>
          </Container>
        </div>
        <AboutAndQuote/>
      </FadeIn>
    </div>
    </Layout>
  );
};

export default Index;